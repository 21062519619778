import Accordion from 'accordion-js';
import { isDesktop } from './helpers';

document.onreadystatechange = () => {
  if (document.readyState === 'complete') {
    if (!isDesktop()) {
      
      for(var i = 1; i <= 5; i++){

        if (document.querySelector('#footer-' + i)) {
          new Accordion('#footer-' + i, {
            duration: 300,
            ariaEnabled: true,
            collapse: true,
            showMultiple: false,
            openOnInit: [],
            activeClass: 'collapsed'
          });
          
        }
      }

    }
  }
};