/**
 * Helpers
 */
import { fromBreakpoint, onVisibilityChange, toBreakpoint } from './helpers'

/**
 * Web standards
 */
import './cookieconsent'
import './softscroll'
import lazyLoadInstance from './lazyload'
import './accordion'
import './header.video'
import { modal } from './modal'
import 'alpinejs/dist/cdn.min.js'

/**
 * Scripts which don't need dynamic import
 */
let wpcf7forms = document.querySelector('.wpcf7-form')
if (wpcf7forms) {
    const jquery = import('jquery')
    global.jQuery = require('jquery')
    window.jQuery = require('jquery')
}

addEventListener('DOMContentLoaded', () => {
    if (
        document.querySelectorAll('.swiper').length > 0 ||
        document.querySelectorAll('#afspraak').length > 0
    ) {
        const swiperCss = import('swiper/css')
        const swiperCssNavigation = import('swiper/css/navigation')
        const swiperCssPagination = import('swiper/css/pagination')
    }

    if (document.querySelectorAll('div[data-modal-target]').length > 0) {
        modal.init()
    }
})
addEventListener('filterStateChange', (event) => {
        lazyLoadInstance.update()
})

/**
 * Dynamic import scripts
 */
const elements = [
    '#header-video',
    '.swiper',
    '#brands',
    '#categories',
    '#postlist',
    '#usps',
    '#latest-products',
    '#popular-products',
    '.product-featured-image',
    'div[data-modal-target]',
    '#afspraak',
    '.woocommerce-cart-form',
    '[data-product-filters]',
    '#custom-products',
]

;[].forEach.call(elements, (element) => {
    if (element !== '') {
        if (
            document.querySelector(element) !== null &&
            document.querySelector(element) !== undefined
        ) {
            const lazyloadHandler = onVisibilityChange(
                document.querySelector(element),
                function () {
                    if (element === '#usps') {
                        const uspsSwiper = import('./usps.swiper')
                    }

                    if (element === '#brands') {
                        const brandsSwiper = import('./brands.swiper')
                    }

                    if (element === '#categories' || element === '#postlist') {
                        const categoriesSwiper = import('./categories.swiper')
                    }

                    if (
                        element === '#latest-products' ||
                        element === '#popular-products'
                    ) {
                        const latestProductsSwiper = import('./products.swiper')
                    }

                    if (element === '#custom-products') {
                        const customProductsSwiper = import(
                            './custom-products.swiper'
                        )
                    }

                    if (element === '.product-featured-image') {
                        const latestProductsSwiper = import(
                            './product-images.swiper'
                        )
                    }

                    if (element === '#afspraak') {
                        const swiperCss = import('swiper/css')
                        const swiperCssNavigation = import(
                            'swiper/css/navigation'
                        )
                        const swiperCssPagination = import(
                            'swiper/css/pagination'
                        )
                        const appointmentIframes = import('./appointments')
                    }

                    if (element === '.woocommerce-cart-form') {
                        const cart = import('./woocommerce/update-cart')
                    }

                    if (element === '[data-product-filters]') {
                        const filters = import('./woocommerce/filter-products')
                    }
                }
            )

            if (window.addEventListener) {
                addEventListener('DOMContentLoaded', lazyloadHandler, false)
                addEventListener('load', lazyloadHandler, false)
                addEventListener('scroll', lazyloadHandler, false)
                addEventListener('resize', lazyloadHandler, false)
            } else if (window.attachEvent) {
                attachEvent('onDOMContentLoaded', lazyloadHandler) // Internet Explorer 9+ :(
                attachEvent('onload', lazyloadHandler)
                attachEvent('onscroll', lazyloadHandler)
                attachEvent('onresize', lazyloadHandler)
            }
        }
    }
})
