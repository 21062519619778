import LazyLoad from "vanilla-lazyload";

const images = document.querySelectorAll("img.lazy, video.lazy, iframe");

const lazyLoadInstance = new LazyLoad({
  unobserve_entered: true,
  webp: true,
  elements_selector: ".lazy"
}, 
images
);
const createLazyLoadInstance = () => {
	return lazyLoadInstance 
};

const delayedCreateLazyLoadInstance = () => {
  setTimeout(createLazyLoadInstance, 100);
}

document.addEventListener("DOMContentLoaded", createLazyLoadInstance);

export default lazyLoadInstance;